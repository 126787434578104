module.exports = new Promise(resolve => {
    const hostname = window.location.hostname
    let remoteUrl = 'https://'

    if (!hostname.includes('local')) {
      remoteUrl += 'example-'
    }

    remoteUrl += hostname

    if (hostname.includes('local')) {
      remoteUrl += ':3006'
    }

    remoteUrl += '/exampleRemoteEntry.js'

    if (hostname.includes('rotw.uplight.io')) {
      const envName = hostname.split('.')[1]
      remoteUrl = 'https://storage.googleapis.com/example-upsuite-' + envName + '-rotw/build/exampleRemoteEntry.js'
    }

    if (hostname.includes('upsuite.uplightplatform.com')) {
      remoteUrl = 'https://storage.googleapis.com/example-upsuite-prd-rotw/build/exampleRemoteEntry.js'
    }

    const script = document.createElement('script')
    script.src = remoteUrl
    script.onload = () => {
      const proxy = {
        get: (request) => window.example.get(request),
        init: (arg) => {
          try {
            return window.example.init(arg)
          } catch(e) {
            console.log('example remote container already initialized')
          }
        }
      }
      resolve(proxy)
    }
    document.head.appendChild(script);
  })
  ;